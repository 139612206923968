<template>
  <div>
    <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
      <b-row>
        <b-col>
          <b-form-group
            label="Company Code"
          >
            <custom-select
              v-model="filter.code"
              label="code"
              multiple
              placeholder="Select company code"
              :options="clientList"
              :reduce="({ code }) => code"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Company Name"
          >
            <custom-select
              v-model="filter.name"
              label="name"
              multiple
              placeholder="Select company name"
              :options="clientList"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="City"
          >
            <custom-select
              v-model="filter.city"
              label="name"
              multiple
              placeholder="Select city"
              :options="city"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="PIC Name"
          >
            <custom-select
              v-model="filter.picName"
              label="picName"
              multiple
              placeholder="Select pic name"
              :options="clientList"
              :reduce="({ picName }) => picName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="button-project mt-5 d-flex justify-content-end">
        <b-button
          class="reset mr-3"
          variant="outline-warning"
          @click="resetFilter"
        >
          Reset Filter
        </b-button>
        <b-button
          class="next"
          variant="warning"
          @click="$refs.table.refresh()"
        >
          Apply Filter
        </b-button>
      </div>
    </b-card>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="mt-3 d-flex justify-content-between">
        <b-button
          class="next"
          variant="warning"
          v-b-modal.modal-add-client
        >
          Add New Client
        </b-button>
        <b-form-group class="m-0">
          <b-input-group>
            <b-form-input
              v-model="search"
              placeholder="Search..."
              @change="$refs.table.refresh()"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <br/>
      <b-row v-show="!items.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png"/>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        v-show="items.length"
        :items="fetchClients"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        striped
        responsive
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>
        <template #cell(action)="{ item }">
          <div class="data">
            <b-link
              class="text-secondary mx-2"
              @click="goToEdit(item.id)"
            >
              <fa-icon icon="edit" />
            </b-link>
            <b-link
              class="text-secondary mx-2"
              @click="goToView(item.id)"
            >
              <fa-icon icon="eye" />
            </b-link>
            <b-link
              class="text-secondary mx-2"
              @click="goToDelete(item)"
            >
              <fa-icon icon="trash-alt" />
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{`Show ${totalRows === 0 ? 0 : ((currentPage-1) * perPage) + 1} to ${totalRows > ((currentPage) * perPage) ? ((currentPage) * perPage) : totalRows} of ${totalRows} entries`}}
          </span >
        </div>
      </div>
    </b-card>
    <modal-add-client @submit="submitClient" />
    <modal-view-client :detail="detail" />
    <modal-edit-client :form="detail" @submit="updateClient" :options="options" />
    <modal-view-project-list :project-list="projectList" />
    <modal-delete-client :client-name="formDelete.name" @remove="removeClient" />
  </div>
</template>

<script>
import api from '@/api'
import ModalAddClient from '@/components/ModalAddClient.vue'
import ModalViewClient from './ModalViewClient.vue'
import ModalEditClient from './ModalEditClient.vue'
import ModalViewProjectList from './ModalViewProjectList.vue'
import ModalDeleteClient from './ModalDeleteClient.vue'

export default {
  components: {
    ModalAddClient,
    ModalViewClient,
    ModalEditClient,
    ModalViewProjectList,
    ModalDeleteClient
  },

  data: () => ({
    clientList: [],
    city: [],
    picName: [],
    sortBy: '',
    search: '',
    filter: {
      code: '',
      name: '',
      city: '',
      picName: ''
    },
    busy: false,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      { key: 'no', label: 'No', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'code', label: 'Client Code', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'name', label: 'Client Name', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'picName', label: 'PIC Name', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'picEmail', label: 'Email (PIC)', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'projectQuantity', label: 'Project Quantity', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    pageOptions: [10, 20, 50, 100],
    items: [],
    detail: {},
    projectList: [],
    formDelete: {
      name: null,
      id: null
    },
    options: {
      province: [],
      city: [],
      district: []
    }
  }),

  computed: {
    stringifiedFilters() {
      let str = ''
      for (const key in this.filter) {
        if (this.filter[key].length === 1) {
          str += `,${key}==${this.filter[key]}`
        } else if (this.filter[key].length > 1) {
          str += `,${key}==${this.filter[key].join('|')}`
        }
      }
      return str
    }
  },

  created() {
    this.fetchClientOption()
    this.fetchCitiesOption()
  },

  methods: {
    async fetchClients() {
      const { data } = await api.client.clientList({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: `(name|code|city|picName|state|country)@=*${this.search}${this.stringifiedFilters}`,
        sorts: this.sortBy
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    async fetchClientOption() {
      const { data } = await api.client.list()
      this.clientList = data.data
    },
    async fetchCitiesOption() {
      const { data } = await api.address.city()
      this.city = data.data
    },
    resetFilter() {
      this.filter = this.$options.data().filter
      this.$refs.table.refresh()
    },
    async submitClient(form) {
      try {
        await api.client.save(form)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save Client', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.$bvModal.hide('modal-add-client')
          this.$refs.table.refresh()
        }, 2000)
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async goToView(id) {
      const { data } = await api.client.get(id)
      const list = await api.client.projectList(id)

      this.detail = data.data
      this.projectList = list.data.data

      this.projectList.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })

      this.$bvModal.show('modal-view-client')
    },
    async goToEdit(id) {
      const { data } = await api.client.get(id)
      this.detail = data.data
      await this.fetchProvince(this.detail.country)
      await this.fetchCity(this.detail.province)
      await this.fetchDistrict(this.detail.city)
      this.$bvModal.show('modal-edit-client')
    },
    async fetchCountry() {
      const { data } = await api.address.country()
      return data.data
    },
    async fetchProvince(country) {
      const countries = await this.fetchCountry()
      let countryId = null
      countries.map(v => {
        if (v.name === country) countryId = v.id
      })
      const { data } = await api.address.provinceById(countryId)
      this.options.province = data.data
    },
    async fetchCity(province) {
      let provinceId = null
      this.options.province.map(v => {
        if (v.name === province) provinceId = v.id
      })
      const { data } = await api.address.cityById(provinceId)
      this.options.city = data.data
    },
    async fetchDistrict(city) {
      let cityId = null
      this.options.city.map(v => {
        if (v.name === city) cityId = v.id
      })
      const { data } = await api.address.districtById(cityId)
      this.options.district = data.data
    },
    goToDelete(item) {
      this.formDelete = {
        ...item
      }
      this.$bvModal.show('modal-delete-client')
    },
    async removeClient() {
      try {
        await api.client.deleteClient(this.formDelete.id)
        this.$nextTick(() => {
          this.$bvToast.toast(`Success delete client ${this.formDelete.name}`, {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async updateClient(item) {
      try {
        const form = {
          code: item.companyCode,
          name: item.companyName,
          address: item.companyAddress,
          country: item.country.name ? item.country.name : item.country,
          state: item.province.name ? item.province.name : item.province,
          city: item.city.name ? item.city.name : item.city,
          district: item.district.name ? item.district.name : item.district,
          zipCode: item.zipCode,
          picName: item.picName,
          picPhone: item.picPhone,
          picEmail: item.picEmail,
          invoiceName: item.invoiceName,
          invoiceEmail: item.invoiceEmail
        }
        await api.client.update(item.id, form)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-edit-client')
          this.$bvToast.toast('Success update client', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 0 16px;
}
</style>

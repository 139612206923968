<template>
  <b-modal
    id="modal-view-client"
    title="View Detail Client"
    hide-footer
    centered
  >
    <div class="">
      <h6 class="mb-4 font-weight-bold">Company Detail</h6>
      <b-row>
        <b-col>
          <div class="text-muted">Company Name</div>
          <div class="font-weight-bold">{{ detail.companyName }}</div>
        </b-col>
        <b-col>
          <div class="text-muted">Company Code</div>
          <div class="font-weight-bold">{{ detail.companyCode }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Company Address</div>
          <div class="font-weight-bold">{{ detail.companyAddress }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Country</div>
          <div class="font-weight-bold">{{ detail.country }}</div>
        </b-col>
        <b-col>
          <div class="text-muted">Province</div>
          <div class="font-weight-bold">{{ detail.province }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">City</div>
          <div class="font-weight-bold">{{ detail.city }}</div>
        </b-col>
        <b-col>
          <div class="text-muted">District</div>
          <div class="font-weight-bold">{{ detail.district }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Zip Code</div>
          <div class="font-weight-bold">{{ detail.zipCode }}</div>
        </b-col>
      </b-row>
      <h6 class="my-4 font-weight-bold">PIC Client</h6>
      <b-row>
        <b-col>
          <div class="text-muted">PIC Name</div>
          <div class="font-weight-bold">{{ detail.picName }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Email PIC</div>
          <div class="font-weight-bold">{{ detail.picEmail }}</div>
        </b-col>
        <b-col>
          <div class="text-muted">Phone Number</div>
          <div class="font-weight-bold">{{ detail.picPhone }}</div>
        </b-col>
      </b-row>
      <h6 class="my-4 font-weight-bold">Invoice Detail</h6>
      <b-row>
        <b-col>
          <div class="text-muted">Invoice Name</div>
          <div class="font-weight-bold">{{ detail.invoiceName }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-muted">Email PIC</div>
          <div class="font-weight-bold">{{ detail.invoiceEmail }}</div>
        </b-col>
      </b-row>
      <div class="mt-4 d-flex align-items-center justify-content-between">
        <h6 class="font-weight-bold">Project List</h6>
        <div>
          <b-button
            class="next"
            variant="warning"
            v-b-modal.modal-view-project-list
          >
            View Project List
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-3, .col-6 {
  padding: 7px 12px;
  font-size: 13px;
}
</style>

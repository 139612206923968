<template>
  <b-modal
    id="modal-edit-client"
    title="Edit Client"
    hide-footer
    size="xl"
  >
    <validation-observer
      ref="form"
      slim
    >
      <b-row>
        <b-col>
          <validation-provider
            name="Company Code"
            rules="required|min:3|max:10"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Company Code *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Company Code"
                v-model="form.companyCode"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Company Name"
            rules="required|min:3|max:255"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Company Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Company Name"
                v-model="form.companyName"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <validation-provider
            name="Company Address"
            rules="required|max:255"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Company Address *"
              :invalid-feedback="errors[0]"
            >
            <b-form-textarea
              v-model="form.companyAddress"
              placeholder="Enter Company Address"
              rows="4"
              max-rows="6"
              :class="{ 'is-invalid': errors.length }"
            />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="6">
              <validation-provider
                name="Country"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Country *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.country"
                    label="name"
                    :clearable="false"
                    placeholder="Select Country"
                    :options="countryOption"
                    @input="fetchProvince(form.country)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                name="Province"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Province *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.province"
                    label="name"
                    :clearable="false"
                    :options="options.province"
                    placeholder="Select Province"
                    @input="fetchCity(form.province)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <validation-provider
                name="City"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="City *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.city"
                    label="name"
                    :clearable="false"
                    placeholder="Select City"
                    :options="options.city"
                    @input="fetchDistrict(form.city)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider
                name="District"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="District *"
                  :invalid-feedback="errors[0]"
                >
                  <custom-select
                    v-model="form.district"
                    :clearable="false"
                    label="name"
                    :options="options.district"
                    placeholder="Select District"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Zipcode "
              >
                <b-form-input
                  placeholder="Enter Zipcode"
                  v-model="form.zipCode"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <h5>PIC Client</h5>
      <b-row>
        <b-col>
          <validation-provider
            name="PIC Name"
            rules="required|min:3|max:50"
            v-slot="{ errors }"
          >
            <b-form-group
              label="PIC Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter PIC Name"
                v-model="form.picName"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <b-row>
            <b-col cols="6">
              <validation-provider
                name="Email PIC"
                rules="required|email"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Email PIC *"
                  :invalid-feedback="errors[0]"
                >
                <b-form-input
                    placeholder="Enter Email PIC"
                    v-model="form.picEmail"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                name="Phone Number"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group
                  label="Phone Number *"
                  :invalid-feedback="errors[0]"
                >
                  <input
                    class="form-control"
                    placeholder="Enter no handphone"
                    v-model="form.picPhone"
                    type="text"
                    @input="restrictInput($event)"
                    :class="{ 'is-invalid': errors.length }"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <h5>Invoice Detail</h5>
      <b-row>
        <b-col>
          <validation-provider
            name="Invoice Name"
            rules="required|max:25"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Invoice Name *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Invoice Name"
                v-model="form.invoiceName"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            name="Email Invoice"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Email Invoice *"
              :invalid-feedback="errors[0]"
            >
              <b-form-input
                placeholder="Enter Email Invoice"
                v-model="form.invoiceEmail"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-col>
        <b-button
          class="primary shadow"
          variant="warning"
          block
          :disabled="isEnable"
          @click="submit"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import moment from 'moment'
import api from '@/api'

export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isEnable: false,
    countryOption: []
  }),

  created() {
    this.fetchCountry()
  },

  methods: {
    restrictInput(event) {
      if (/^\d*$/.test(event.target.value)) {
        return true
      } else {
        this.picPhone = this.picPhone.slice(0, -1)
      }
    },
    formatDate(date) {
      var value = date === '' ? 'YYYY-MM-DD' : moment(date).format('YYYY-MM-DD')
      return value
    },
    async fetchCountry() {
      const { data } = await api.address.country()
      this.countryOption = data.data
      this.provinceOption = []
      this.cityOption = []
      this.districtOption = []
    },
    async fetchProvince(country) {
      this.form.province = null
      this.form.city = null
      this.form.district = null
      const { data } = await api.address.provinceById(country.id)
      this.options.province = data.data
      this.options.city = []
      this.options.district = []
    },
    async fetchCity(province) {
      this.form.city = null
      this.form.district = null
      const { data } = await api.address.cityById(province.id)
      this.options.city = data.data
      this.options.district = []
    },
    async fetchDistrict(city) {
      this.form.district = null
      const { data } = await api.address.districtById(city.id)
      this.options.district = data.data
    },
    async submit() {
      this.isEnable = true
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.isEnable = false
        return false
      }
      await this.$emit('submit', this.form)
      this.isEnable = false
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-edit-client","title":"Edit Client","hide-footer":"","size":"xl"}},[_c('validation-observer',{ref:"form",attrs:{"slim":""}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Company Code","rules":"required|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Code *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Company Code"},model:{value:(_vm.form.companyCode),callback:function ($$v) {_vm.$set(_vm.form, "companyCode", $$v)},expression:"form.companyCode"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required|min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Company Name"},model:{value:(_vm.form.companyName),callback:function ($$v) {_vm.$set(_vm.form, "companyName", $$v)},expression:"form.companyName"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Address *","invalid-feedback":errors[0]}},[_c('b-form-textarea',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Company Address","rows":"4","max-rows":"6"},model:{value:(_vm.form.companyAddress),callback:function ($$v) {_vm.$set(_vm.form, "companyAddress", $$v)},expression:"form.companyAddress"}})],1)]}}])})],1),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","clearable":false,"placeholder":"Select Country","options":_vm.countryOption},on:{"input":function($event){return _vm.fetchProvince(_vm.form.country)}},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Province *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","clearable":false,"options":_vm.options.province,"placeholder":"Select Province"},on:{"input":function($event){return _vm.fetchCity(_vm.form.province)}},model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"City *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"label":"name","clearable":false,"placeholder":"Select City","options":_vm.options.city},on:{"input":function($event){return _vm.fetchDistrict(_vm.form.city)}},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"District","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"District *","invalid-feedback":errors[0]}},[_c('custom-select',{class:{ 'is-invalid': errors.length },attrs:{"clearable":false,"label":"name","options":_vm.options.district,"placeholder":"Select District"},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Zipcode "}},[_c('b-form-input',{attrs:{"placeholder":"Enter Zipcode","type":"number"},model:{value:(_vm.form.zipCode),callback:function ($$v) {_vm.$set(_vm.form, "zipCode", $$v)},expression:"form.zipCode"}})],1)],1)],1)],1)],1),_c('h5',[_vm._v("PIC Client")]),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"PIC Name","rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"PIC Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter PIC Name"},model:{value:(_vm.form.picName),callback:function ($$v) {_vm.$set(_vm.form, "picName", $$v)},expression:"form.picName"}})],1)]}}])})],1),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email PIC","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email PIC *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Email PIC"},model:{value:(_vm.form.picEmail),callback:function ($$v) {_vm.$set(_vm.form, "picEmail", $$v)},expression:"form.picEmail"}})],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone Number *","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.picPhone),expression:"form.picPhone"}],staticClass:"form-control",class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter no handphone","type":"text"},domProps:{"value":(_vm.form.picPhone)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "picPhone", $event.target.value)},function($event){return _vm.restrictInput($event)}]}})])]}}])})],1)],1)],1)],1),_c('h5',[_vm._v("Invoice Detail")]),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Invoice Name","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Invoice Name *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Invoice Name"},model:{value:(_vm.form.invoiceName),callback:function ($$v) {_vm.$set(_vm.form, "invoiceName", $$v)},expression:"form.invoiceName"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Email Invoice","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email Invoice *","invalid-feedback":errors[0]}},[_c('b-form-input',{class:{ 'is-invalid': errors.length },attrs:{"placeholder":"Enter Email Invoice"},model:{value:(_vm.form.invoiceEmail),callback:function ($$v) {_vm.$set(_vm.form, "invoiceEmail", $$v)},expression:"form.invoiceEmail"}})],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"primary shadow",attrs:{"variant":"warning","block":"","disabled":_vm.isEnable},on:{"click":_vm.submit}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h6',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Filter")]),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Company Code"}},[_c('custom-select',{attrs:{"label":"code","multiple":"","placeholder":"Select company code","options":_vm.clientList,"reduce":function (ref) {
	var code = ref.code;

	return code;
}},model:{value:(_vm.filter.code),callback:function ($$v) {_vm.$set(_vm.filter, "code", $$v)},expression:"filter.code"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Company Name"}},[_c('custom-select',{attrs:{"label":"name","multiple":"","placeholder":"Select company name","options":_vm.clientList,"reduce":function (ref) {
	var name = ref.name;

	return name;
}},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"City"}},[_c('custom-select',{attrs:{"label":"name","multiple":"","placeholder":"Select city","options":_vm.city,"reduce":function (ref) {
	var name = ref.name;

	return name;
}},model:{value:(_vm.filter.city),callback:function ($$v) {_vm.$set(_vm.filter, "city", $$v)},expression:"filter.city"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"PIC Name"}},[_c('custom-select',{attrs:{"label":"picName","multiple":"","placeholder":"Select pic name","options":_vm.clientList,"reduce":function (ref) {
	var picName = ref.picName;

	return picName;
}},model:{value:(_vm.filter.picName),callback:function ($$v) {_vm.$set(_vm.filter, "picName", $$v)},expression:"filter.picName"}})],1)],1)],1),_c('div',{staticClass:"button-project mt-5 d-flex justify-content-end"},[_c('b-button',{staticClass:"reset mr-3",attrs:{"variant":"outline-warning"},on:{"click":_vm.resetFilter}},[_vm._v(" Reset Filter ")]),_c('b-button',{staticClass:"next",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.$refs.table.refresh()}}},[_vm._v(" Apply Filter ")])],1)],1),_c('b-card',{staticClass:"mt-3",attrs:{"body-class":"py-4 px-3"}},[_c('div',{staticClass:"mt-3 d-flex justify-content-between"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-add-client",modifiers:{"modal-add-client":true}}],staticClass:"next",attrs:{"variant":"warning"}},[_vm._v(" Add New Client ")]),_c('b-form-group',{staticClass:"m-0"},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Search..."},on:{"change":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('br'),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.items.length),expression:"!items.length"}]},[_c('b-col',[_c('div',{attrs:{"align":"center"}},[_c('img',{attrs:{"src":require("@/assets/icon-no-invoice.png")}})])])],1),_c('b-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length),expression:"items.length"}],ref:"table",attrs:{"items":_vm.fetchClients,"fields":_vm.fields,"per-page":_vm.perPage,"current-page":_vm.currentPage,"sort-by":_vm.sortBy,"striped":"","responsive":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"data"},[_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.goToEdit(item.id)}}},[_c('fa-icon',{attrs:{"icon":"edit"}})],1),_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.goToView(item.id)}}},[_c('fa-icon',{attrs:{"icon":"eye"}})],1),_c('b-link',{staticClass:"text-secondary mx-2",on:{"click":function($event){return _vm.goToDelete(item)}}},[_c('fa-icon',{attrs:{"icon":"trash-alt"}})],1)],1)]}}])}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('span',[_vm._v("Show")]),_c('b-form-select',{staticClass:"page-option ml-2 mr-2 w-auto",attrs:{"options":_vm.pageOptions,"size":"sm"},on:{"change":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('span',[_vm._v("Entries")])],1),_c('div',[_c('b-pagination',{staticClass:"mb-0",attrs:{"per-page":_vm.perPage,"total-rows":_vm.totalRows,"pills":"","hide-goto-end-buttons":""},on:{"input":function($event){return _vm.$refs.table.refresh()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('span',{staticClass:"show-entries mr-auto"},[_vm._v(" "+_vm._s(("Show " + (_vm.totalRows === 0 ? 0 : ((_vm.currentPage-1) * _vm.perPage) + 1) + " to " + (_vm.totalRows > ((_vm.currentPage) * _vm.perPage) ? ((_vm.currentPage) * _vm.perPage) : _vm.totalRows) + " of " + _vm.totalRows + " entries"))+" ")])],1)])],1),_c('modal-add-client',{on:{"submit":_vm.submitClient}}),_c('modal-view-client',{attrs:{"detail":_vm.detail}}),_c('modal-edit-client',{attrs:{"form":_vm.detail,"options":_vm.options},on:{"submit":_vm.updateClient}}),_c('modal-view-project-list',{attrs:{"project-list":_vm.projectList}}),_c('modal-delete-client',{attrs:{"client-name":_vm.formDelete.name},on:{"remove":_vm.removeClient}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
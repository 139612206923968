<template>
  <b-modal
    id="modal-view-project-list"
    title="Project List"
    hide-footer
    centered
  >
    <b-table
      :items="projectList"
      :fields="fields"
      empty-text="No Data"
      striped
      responsive
      show-empty
    >
      <template #cell(status)="{ item }">
        <b-badge
          class="px-3 py-2 ml-3"
          :class="changeStatus(item.status)"
        >
          {{ item.status }}
        </b-badge>
      </template>
    </b-table>
  </b-modal>
</template>

<script>

export default {
  props: {
    projectList: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    fields: [
      { key: 'name', label: 'Project Name', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'status', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'periode', tdClass: 'text-center', thClass: 'text-center' }
    ]
  }),

  methods: {
    changeStatus(status) {
      if (status === 'Prospect') {
        return 'prospect'
      } else if (status === 'Proposal Submitted') {
        return 'proposalSubmitted'
      } else if (status === 'Preparation') {
        return 'preparation'
      } else if (status === 'In Progress') {
        return 'inProgress'
      } else if (status === 'On Hold') {
        return 'onHold'
      } else if (status === 'Maintenance') {
        return 'maintenance'
      } else if (status === 'Done') {
        return 'done'
      } else {
        return 'close'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.prospect {
  background: #D623AE;
}

.proposalSubmitted {
  background: #FBB233;
}

.preparation {
  background: #5382FB;
}

.inProgress {
  background: #5A4CAF;
}

.onHold {
  background: #4CAF50;
}

.maintenance {
  background: #CCCCCC;
}

.done {
  background: #4CAF50;
}

.close {
  background: #DB1111;
}
</style>
